import { RoleService } from '../../../../service/role/role.service';
import DataContainer from '../../../container/DataContainer/DataContainer';

function Roles() {
    return (
        <DataContainer
            resourceService={new RoleService()}
            title="Roles"
            deleteAllowed={false}
            updateAllowed={false}
        />
    )
}

export default Roles;