import * as actionType from '../action-types/authentication.action-types';
import { AuthenticationService } from '../../service/authentication/authentication.service';
import { AuthenticationInterface, AuthenticationPayloadInterface } from '../../service/authentication/authentication.interface';

const onDispatch = (type: string, payload: any) => ({ type, payload });
const authenticationService = new AuthenticationService();

export const loginUser = (authenticationData: AuthenticationInterface) => {
    return async (dispatch: any) => {
        dispatch(onDispatch(actionType.LOGIN_USER, null));

        await authenticationService.loginUser(
            authenticationData,
            (response: AuthenticationPayloadInterface) => {
                dispatch(onDispatch(actionType.LOGIN_USER_SUCCESS, response));
            },
            (error: any) => {
                dispatch(onDispatch(actionType.LOGIN_USER_ERROR, error));
            }
        );
    };
}

export const logoutUser = () => {
    return async (dispatch: any) => {
        await authenticationService.logoutUser()
            .then(() => {
                dispatch(onDispatch(actionType.LOGOUT_USER, null));
            })
    }
}

export const validateToken = () => {
    return async (dispatch: any) => {
        dispatch(onDispatch(actionType.VALIDATE_TOKEN, null));

        await authenticationService.validateToken(
            (response: AuthenticationPayloadInterface) => {
                dispatch(onDispatch(actionType.VALIDATE_TOKEN_SUCCESS, response));
            },
            (error: any) => {
                dispatch(onDispatch(actionType.VALIDATE_TOKEN_ERROR, error));
            }
        );
    };
}


