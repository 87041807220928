import { useEffect, useState } from 'react';
import { Card, CardActions, CardContent, OutlinedInput, Typography, Button, IconButton, Snackbar, InputAdornment, List, ListItem, ListSubheader } from '@mui/material';
import { ContentCopyOutlined, VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { ACCESS_TOKEN_NAME } from '../../../common/constants/constants';
import './Overview.css';

function Overview() {
  const [apiKey, setApiKey] = useState<string | null>(null);
  const [apiKeyExpirationDate, setApiKeyExpirationDate] = useState<string>("01.01.2022");
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");

  useEffect(() => {
    setApiKey(localStorage.getItem(ACCESS_TOKEN_NAME));
  }, []);

  const handleCopy = () => {
    if (apiKey === null) return;

    navigator.clipboard.writeText(apiKey);
    setSnackbarMessage("Token kopiert!");
    setSnackbarOpen(true);
  };

  const handleGenerate = () => {
    setSnackbarMessage("Neuer Key generiert!");
    setSnackbarOpen(true);
  };

  return (
    <div className="overview">
      <List>
        <ListSubheader className="subheader">Dokumentation & Richtlinien</ListSubheader>
        <ListItem className="list-item">
          <Card className="overview-card">
            <CardContent className="card-content">
              <Typography variant="body2" component="p">
                Wir empfehlen Ihnen, sich vor der Verwendung der API mit den Dokumentationen und Richtlinien vertraut zu machen.
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="medium" variant="contained" href="/documentation" className="success-button">Erfahre mehr</Button>
            </CardActions>
          </Card>
        </ListItem>
        <ListSubheader className="subheader">API Key</ListSubheader>
        <ListItem className="list-item">
          <Card className="overview-card">
            <CardContent className="card-content">
              <Typography variant="body2" component="p">
                Hier finden Sie Ihren API Key. Dieser Key ist notwendig, um die API zu verwenden.
              </Typography>
              <OutlinedInput
                type={showPassword ? 'text' : 'password'}
                value={apiKey}
                inputProps={{ readOnly: true }}
                size="small"
                sx={{ maxWidth: "1000px" }}
                className="api-key-input"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </IconButton>
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleCopy}
                    >
                      <ContentCopyOutlined />
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Typography variant="h5" component="h5">
                {/* Dieser Key läuft am {apiKeyExpirationDate} aus!*/}
              </Typography>
            </CardContent>
            <CardActions>
              {/*<Button size="medium" variant="contained" className="success-button" onClick={handleGenerate}>Neu generieren</Button>*/}
            </CardActions>
          </Card>
        </ListItem>
      </List>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </div>
  )
}

export default Overview;
