import { useEffect } from 'react';
import DocumentTitleContainer from '../../container/DocumentTitleContainer/DocumentTitleContainer';
import { Card, CardContent, CardHeader, Typography, Box, FormLabel, Button, OutlinedInput } from '@mui/material';
import useNotificationServiceController from '../../../hooks/useNotificationService.controller';
import NotificationDataCardProps from './NotificationDataCard/NotificationDataCard';
import './NotificationService.css';


function NotificationService() {
  const { data, newData, setNewData, getDataByUser, createData, deleteData } = useNotificationServiceController();

  useEffect(() => {
    getDataByUser();
  }, []);

  console.log(data);

  return (
    <DocumentTitleContainer title="Benachrichtigungs-Service">
      <div className="data-container notification-service">

        <Card className="data-card">
          <CardContent className="card-content">
            <CardHeader className="card-header" title={
              <Box className="header-box" display="flex" justifyContent="space-between" alignItems="center">
                <div className="header-box-left">
                  <Typography variant="h4" align="left" gutterBottom fontWeight={700} color="primary" className="content-title">
                    Benachrichtigungs-Service
                  </Typography>
                </div>
              </Box>
            } />
          </CardContent>
        </Card>

        <div className="group-card-container">

          <Card className="group-card">
            <CardContent className="card-content">
              <div className="description">
                <Typography variant="body1" align="left" gutterBottom className="content-text">
                  Wir analysieren Problempunkte und Barrieren entlang spezifischer Routen in verschiedenen Gebieten.
                  Sie werden von uns benachrichtigt, sobald wir potenzielle Problempunkte identifiziert haben.
                </Typography>
                <Typography variant="body1" align="left" gutterBottom className="content-text content-text-bold">
                  Bitte teilen Sie uns den Ort mit, für den Sie Benachrichtigungen empfangen möchten.
                </Typography>
              </div>
            </CardContent>
          </Card>

          <Card className="group-card">
            <CardContent className="card-content">
              <div className="input-container">
                <div className="input-group">
                  <FormLabel className="label" component="legend">Ortsname</FormLabel>
                  <OutlinedInput className="textfield" id="address" type="text" value={newData?.location} onChange={(event) => setNewData({ ...newData, location: event.target.value })} placeholder="z.B. Berlin" />
                </div>
                <div className="input-group">
                  <FormLabel className="label" component="legend">Postleizahl</FormLabel>
                  <OutlinedInput className="textfield" id="text" type="number" value={newData?.postcode === 0 ? null : newData?.postcode} onChange={(event) => setNewData({ ...newData, postcode: parseInt(event.target.value) })} placeholder="10969" />
                </div>
              </div>
              <div className="input-group">
                <Button className="button" variant="contained" color="primary" onClick={createData} fullWidth>Benachrichtigungen aktivieren</Button>
              </div>
            </CardContent>
          </Card>

        </div>

        {data.length !== 0 && (
          <NotificationDataCardProps data={data} deleteData={deleteData} />
        )}

      </div>
    </DocumentTitleContainer>
  )
}

export default NotificationService;