import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootStateInterface } from './store/state-interfaces/root.state-interface';
import * as authenticationAction from './store/actions/authentication.actions';
import { AuthenticationStateInterface } from './store/state-interfaces/authentication.state-interface';
import { useRoutes } from 'react-router-dom';
import routes from './routes/routes';
import SnackbarComponent from './components/ui/Snackbar/SnackbarComponent';
import Splash from './components/ui/Splash/Splash';

interface AppPropsInterface {
  authentication: AuthenticationStateInterface,
  validateToken: () => void
}

function App(props: AppPropsInterface) {
  const { authentication, validateToken } = props;
  const routing = useRoutes(routes(authentication.authentication ? true : false, authentication.authentication?.role || ''));

  useEffect(() => {
    validateToken();
  }, []);

  if (authentication.tokenValidation.loading) {
    return (
      <Splash />
    )
  }

  return (
    <>
      {routing}
      <SnackbarComponent />
    </>
  )
}

const mapStateToProps = (state: RootStateInterface) => {
  return {
    authentication: state.authentication
  }
}

const mapActionToProps = (dispatch: any) => {
  return {
    validateToken: () => { dispatch(authenticationAction.validateToken()) }
  }
}

export default connect(mapStateToProps, mapActionToProps)(App);