import React, { useState } from 'react';
import { Avatar, Menu, MenuItem, Button, Typography } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { LogoutRounded, SettingsRounded } from '@mui/icons-material';
import { connect } from 'react-redux';
import * as authenticationAction from '../../../store/actions/authentication.actions';
import EditProfile from '../../pages/EditProfile/EditProfile';
import './Profile.css';

interface ProfilenPropsInterface {
    logoutUser: () => void,
}

function Profile(props: ProfilenPropsInterface) {
    const { logoutUser } = props;
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [expanded, setExpanded] = useState<boolean>(false);

    const handleOpen = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        logoutUser();
    };

    const handleEditProfile = () => {
        setOpenDialog(true);
        setAnchorEl(null);
    }

    const handleCloseDialog = () => {
        setOpenDialog(false);
    }

    return (
        <>
            <div className="profile-container">
                <Button variant="text" onClick={handleOpen} className="profile" startIcon={
                    <Avatar className="avatar">
                        <AccountCircleIcon className="icon" />
                    </Avatar>
                }>
                    <Typography component="p" variant="body1" className="profile-name" textTransform="capitalize">
                        Profil
                    </Typography>
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    className="menu"
                    classes={{ paper: "menu-paper", list: "menu-list" }}
                >
                    <MenuItem onClick={handleEditProfile} className="menu-item profile" classes={{ root: "menu-item-root" }}>
                        <SettingsRounded />
                        <div>Einstellungen</div>
                    </MenuItem>
                    <MenuItem onClick={handleLogout} className="menu-item logout" classes={{ root: "menu-item-root" }}>
                        <LogoutRounded />
                        <div>Logout</div>
                    </MenuItem>
                </Menu>
            </div>
            <EditProfile
                openDialog={openDialog}
                closeDialogHandler={handleCloseDialog}
            />
        </>
    );
}

const mapActionToProps = (dispatch: any) => {
    return {
        logoutUser: () => { dispatch(authenticationAction.logoutUser()) }
    }
}

export default connect(null, mapActionToProps)(Profile);