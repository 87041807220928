import { TableHead, TableRow, TableCell, TableBody, Skeleton } from '@mui/material';
import { SKELETON_COLUMNS, SKELETON_ROWS, SKELETON_WIDTH } from '../../../../common/constants/constants';

function LoadingTableData() {
    return (
        <>
            <TableHead>
                <TableRow className="table-row">

                    {Array.from(Array(SKELETON_COLUMNS).keys()).map((index: number) => (
                        <TableCell key={index} className="table-cell">
                            <Skeleton variant="text" width={SKELETON_WIDTH} />
                        </TableCell>
                    ))}

                </TableRow>
            </TableHead>
            <TableBody>

                {Array.from(Array(SKELETON_ROWS).keys()).map((index: number) => (
                    <TableRow key={index} className="table-row">

                        {Array.from(Array(SKELETON_COLUMNS).keys()).map((index: number) => (
                            <TableCell key={index} className="table-cell">
                                <Skeleton variant="text" width={SKELETON_WIDTH} />
                            </TableCell>
                        ))}

                    </TableRow>
                ))}

            </TableBody>
        </>
    )
}

export default LoadingTableData;