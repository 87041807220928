import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as snackbarActions from '../store/actions/snackbar.actions';
import { RootStateInterface } from '../store/state-interfaces/root.state-interface';
import { LocationResponsibilityInterface, EditLocationResponsibilityInterface } from '../service/location-responsibility/location-responsibility.interface';
import { LocationResponsibilityService } from '../service/location-responsibility/location-responsibility.service';

function useNotificationServiceController() {
    const locationResponsibilityService = new LocationResponsibilityService();
    const authentication = useSelector((state: RootStateInterface) => state.authentication);
    const dispatch = useDispatch();
    const [data, setData] = useState<LocationResponsibilityInterface[]>([]);
    const [newData, setNewData] = useState<EditLocationResponsibilityInterface>({
        location: '',
        postcode: 0,
    });
    const [loading, setLoading] = useState({
        getByUser: false,
        create: false,
        delete: false,
    });
    const [error, setError] = useState({
        getByUser: false,
        create: false,
        delete: false,
    });

    const getDataByUser = async () => {
        if (!authentication.authentication?.access_token) return;

        setLoading({ ...loading, getByUser: true });
        try {
            const response = await locationResponsibilityService.getByUser(authentication.authentication.access_token);

            setData(response);
        } catch (exception) {
            setError({ ...error, getByUser: true });

            dispatch(snackbarActions.setErrorMessage('Fehler beim Laden der Daten') as any);
        }

        setLoading({ ...loading, getByUser: false });
    }

    const createData = async () => {
        if (!authentication.authentication?.access_token) return;

        setLoading({ ...loading, create: true });
        try {
            await locationResponsibilityService.create(authentication.authentication.access_token, newData);

            dispatch(snackbarActions.setSuccessMessage('Benachrichtigung erfolgreich hinzugefügt') as any);
            
            getDataByUser();
        } catch (exception) {
            setError({ ...error, create: true });

            dispatch(snackbarActions.setErrorMessage('Fehler beim hinzufügen der Benachrichtigung') as any);
        }

        setLoading({ ...loading, create: false });
    }

    const deleteData = async (id: number) => {
        if (!authentication.authentication?.access_token) return;

        setLoading({ ...loading, delete: true });
        try {
            await locationResponsibilityService.delete(authentication.authentication.access_token, id);

            dispatch(snackbarActions.setSuccessMessage('Benachrichtigung gelöscht') as any);

            getDataByUser();
        } catch (exception) {
            setError({ ...error, delete: true });

            dispatch(snackbarActions.setErrorMessage('Fehler beim Löschen der Daten') as any);
        }

        setLoading({ ...loading, delete: false });
    }

    return {
        data,
        loading,
        error,
        newData,
        setNewData,
        getDataByUser,
        createData,
        deleteData,
    };
}

export default useNotificationServiceController;