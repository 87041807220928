import ABPALogo from '../../../assets/logos/ABPA_logo.jpg';
import { Avatar, Typography } from '@mui/material';
import './SidebarHeader.css';

function SidebarHeader() {
    return (
        <div className="sidebar-header">
            <Avatar className="avatar">
                <img src={ABPALogo} alt="ABPA-Logo" />
            </Avatar>
            <Typography component="h1" variant="h1">
                ABPA
            </Typography>
        </div>
    )
}

export default SidebarHeader;