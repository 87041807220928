import { Typography, Accordion, AccordionSummary, AccordionDetails, TextField, List, ListItem, Card, CardContent, FormLabel, Button, OutlinedInput } from '@mui/material';
import { ExpandMoreOutlined } from '@mui/icons-material';
import FullScreenDialog from '../../ui/FullScreenDialog/FullScreenDialog';
import './EditProfile.css';
import DocumentTitleContainer from '../../container/DocumentTitleContainer/DocumentTitleContainer';

interface EditProfilePropsInterface {
    openDialog: boolean,
    closeDialogHandler: () => void,
}

function EditProfile(props: EditProfilePropsInterface) {
    const { openDialog, closeDialogHandler } = props;

    return (
        <DocumentTitleContainer title="Profil bearbeiten">
            <FullScreenDialog openDialog={openDialog} closeDialogHandler={closeDialogHandler} title="Einstellungen">
                <div className="profile-dialog-container">
                    <List className="profile-dialog-list">
                        <ListItem className="profile-dialog-list-item">
                            <div className="profile-dialog-header">
                                <Typography variant="h6" component="div" className="personal-information-title">
                                    Account Informationen
                                </Typography>
                            </div>
                            <Card className="profile-dialog-card">
                                <CardContent className="card-content">
                                    <div className="card-item">
                                        <div className="item-summary">
                                            <Typography className="summary-title" variant="body1">Nutzername</Typography>
                                            <Typography className="summary-data" variant="body1">test@email.de</Typography>
                                        </div>
                                    </div>
                                    <div className="card-item">
                                        <div className="item-summary">
                                            <Typography className="summary-title" variant="body1" component="div">Rolle</Typography>
                                            <Typography className="summary-data" variant="body1" component="div">Admin</Typography>
                                        </div>
                                    </div>
                                    <div className="card-item">
                                        <Accordion className="accordion" disableGutters elevation={0} classes={{ root: "accordion-root", expanded: "profile-dialog-accordion-expanded" }}>
                                            <AccordionSummary className="accordion-summary" expandIcon={<ExpandMoreOutlined className="expand-icon" />}>
                                                <Typography className="accordion-summary-title" variant="body1">Passwort ändern</Typography>
                                            </AccordionSummary>
                                            <AccordionDetails className="accordion-details">
                                                <div className="form-container">
                                                    <div className="form-item">
                                                        <FormLabel className="input-label" component="legend">Neues Passwort eingeben</FormLabel>
                                                        <OutlinedInput className="input-field" type="password" fullWidth />
                                                    </div>
                                                    <div className="form-item">
                                                        <FormLabel className="input-label" component="legend">Passwort bestätigen</FormLabel>
                                                        <OutlinedInput className="input-field" type="password" fullWidth />
                                                    </div>
                                                    <div className="form-item">
                                                        <Typography variant="body1" align="left" gutterBottom className="content-text content-text-bold">
                                                            Sie können auch eine genaue Adresse eingeben.
                                                        </Typography>
                                                        <Button className="form-button" variant="contained" color="primary">Speichern</Button>
                                                    </div>
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>
                                </CardContent>
                            </Card>
                        </ListItem>
                    </List>
                </div>
            </FullScreenDialog>
        </DocumentTitleContainer>
    )
}

export default EditProfile;