import { Snackbar, Alert } from '@mui/material';
import { SNACKBAR_HIDE_DURATION } from '../../../../common/constants/constants';

interface SnackbarPropsInterface {
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
    open: boolean;
    resetMessages: () => void;
}

function SnackbarItem(props: SnackbarPropsInterface) {
    const { message, severity, open, resetMessages } = props;

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={SNACKBAR_HIDE_DURATION}
                onClose={resetMessages}
            >
                <Alert
                    onClose={resetMessages}
                    severity={severity}
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}

export default SnackbarItem;