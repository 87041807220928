import { useEffect } from 'react';
import { Card, CardContent, CardHeader, Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import LoadingTableData from './LoadingTableData/LoadingTableData';
import TableItem from './TableItem/TableItem';
import { RootResourceService } from '../../../service/root/root-resource-service';
import useDataContainerController from '../../../hooks/useDataContainer.controller';
import DocumentTitleContainer from '../DocumentTitleContainer/DocumentTitleContainer';
import CreateData from './CreateData/CreateData';
import './DataContainer.css';

interface DataContainerProps {
    title: string;
    resourceService: RootResourceService<any>;
    editResourceObject?: any;
    deleteAllowed: boolean;
    updateAllowed: boolean;
}

function DataContainer(props: DataContainerProps) {
    const { title, resourceService, editResourceObject, deleteAllowed, updateAllowed } = props;
    const { resourceUrl, data, loading, getAllData, deleteData, updateData, createData, openDialog, openDialogHandler, closeDialogHandler, editData, setEditData } = useDataContainerController({
        resourceService: resourceService,
        editResourceObject: editResourceObject ? editResourceObject : {}
    });

    useEffect(() => {
        getAllData();
    }, []);

    return (
        <DocumentTitleContainer title={title}>
            <>
                <div className="data-container">
                    <Card className="data-card">
                        <CardContent className="card-content">
                            <CardHeader className="card-header" title={
                                <Box className="header-box" display="flex" justifyContent="space-between" alignItems="center">
                                    <div className="header-box-left">
                                        <Typography variant="h4" align="left" gutterBottom fontWeight={700} color="primary" className="content-title">
                                            {title}
                                        </Typography>
                                        <Typography variant="subtitle1" align="left" gutterBottom fontWeight={400} className="content-subtitle">
                                            {resourceUrl}
                                        </Typography>
                                    </div>
                                    <div className="header-box-right">
                                        <IconButton className="add-button" onClick={openDialogHandler}>
                                            <Add sx={{ fontSize: 45 }} />
                                        </IconButton>
                                    </div>
                                </Box>
                            } />
                            <TableContainer className="table-container">
                                <Table className="table">

                                    {!loading.getAll && data && data.length > 0 ? (
                                        <>
                                            <TableHead>
                                                <TableRow className="table-row">

                                                    {Object.keys(data[0]).map((head: string, index: number) => (
                                                        <TableCell key={index} className="table-cell">{head}</TableCell>
                                                    ))}

                                                    {(deleteAllowed || updateAllowed) && (
                                                        <TableCell className="table-cell"></TableCell>
                                                    )}

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>

                                                {data.map((item: any, dataIndex: number) => (
                                                    <TableItem
                                                        key={dataIndex}
                                                        item={item}
                                                        onDelete={deleteAllowed ? deleteData : undefined}
                                                        onUpdate={updateAllowed ? updateData : undefined}
                                                    />
                                                ))}

                                            </TableBody>
                                        </>
                                    ) : (
                                        <LoadingTableData />
                                    )}

                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </div>

                <CreateData openDialog={openDialog} closeDialogHandler={closeDialogHandler} data={editData} setData={setEditData} createData={createData} />
            </>

        </DocumentTitleContainer>
    )
}

export default DataContainer;