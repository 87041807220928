import { Card, CardContent, TextField, Button, Paper, Typography, CircularProgress } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { connect } from 'react-redux';
import { RootStateInterface } from '../../../store/state-interfaces/root.state-interface';
import * as authenticationAction from '../../../store/actions/authentication.actions';
import { AuthenticationInterface } from '../../../service/authentication/authentication.interface';
import { AuthenticationStateInterface } from '../../../store/state-interfaces/authentication.state-interface';
import useAuthenticationController from '../../../hooks/useAuthentication.controller';
import { INFO_WEBSITE_URL } from '../../../common/constants/constants';
import './Login.css';

interface LoginPropsInterface {
  authentication: AuthenticationStateInterface,
  loginUser: (payload: AuthenticationInterface) => void
}

function Login(props: LoginPropsInterface) {
  const { authentication, loginUser } = props;
  const { userInformation, setUserInformation, emailError, passwordError, validateEmail, validatePassword } = useAuthenticationController();

  const handleLogin = () => {
    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();

    if (isEmailValid && isPasswordValid) {
      loginUser(userInformation);
    }
  }

  const handleRedirect = () => {
    window.location.href = INFO_WEBSITE_URL;
  }

  return (
    <Paper className="login-container" elevation={10}>
      <Card className="login-card">
        <CardContent className="card-content">
          <div className="card-header">
            <Typography variant="h3" align="left" gutterBottom fontWeight={700} color="primary" className="login-title">
              Anmeldung
            </Typography>
            <Typography variant="h5" align="left" gutterBottom fontWeight={400} className="login-subtitle">
              Willkommen zu ABPA
            </Typography>
          </div>
          <TextField
            error={authentication.login.error || !!emailError ? true : false}
            helperText={!!emailError && "Bitte geben Sie eine gültige Email ein"}
            className="login-input"
            variant="standard"
            required
            label="Email Address"
            margin="normal"
            fullWidth
            value={userInformation.username}
            onChange={(event) => setUserInformation({ ...userInformation, username: event.target.value })}
          />
          <TextField
            error={authentication.login.error || !!passwordError ? true : false}
            helperText={!!passwordError && "Bitte geben Sie eine gültiges Passwort ein"}
            variant="standard"
            required
            label="Password"
            margin="normal"
            fullWidth
            type="password"
            value={userInformation.password}
            onChange={(event) => setUserInformation({ ...userInformation, password: event.target.value })}
          />
          <div className="login-button-container">
            <Typography variant="body1" align="left" gutterBottom fontWeight={400} className="login-error" color="error">
              {authentication.login.error ? "Falsche Email oder Passwort" : ""}
            </Typography>
            <Button
              className="login-button"
              type="submit"
              variant="contained"
              onClick={handleLogin}
              disabled={authentication.login.loading}
              disableElevation
            >
              {authentication.login.loading ? <><CircularProgress size={20} color="secondary" className="loading-indicator" disableShrink />Anmelden</> : "Anmelden"}
            </Button>
          </div>
          <div className="divider">
            <hr className="divider-line" />
            <Typography variant="body1" align="left" gutterBottom fontWeight={400} className="login-subtitle">
              oder
            </Typography>
            <hr className="divider-line" />
          </div>
          <Button
            className="info-button"
            type="button"
            variant="outlined"
            onClick={handleRedirect}
            endIcon={<ArrowForward />}
            fullWidth
          >
            Erfahre mehr
          </Button>
        </CardContent>
      </Card>
    </Paper>
  )
}

const mapStateToProps = (state: RootStateInterface) => {
  return {
    authentication: state.authentication
  }
}

const mapActionToProps = (dispatch: any) => {
  return {
    loginUser: (payload: AuthenticationInterface) => { dispatch(authenticationAction.loginUser(payload)) }
  }
}

export default connect(mapStateToProps, mapActionToProps)(Login);