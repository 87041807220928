import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as snackbarActions from '../store/actions/snackbar.actions';
import { RootResourceService } from '../service/root/root-resource-service';
import { RootStateInterface } from '../store/state-interfaces/root.state-interface';
import { API_BASE_URL } from '../common/constants/constants';

interface useDataContainerControllerInterface<T> {
  resourceService: RootResourceService<T>;
  editResourceObject?: any;
}

function useDataContainerController<T>(props: useDataContainerControllerInterface<T>) {
  const { resourceService, editResourceObject } = props;
  const authentication = useSelector((state: RootStateInterface) => state.authentication);
  const dispatch = useDispatch();
  const resourceUrl: string = resourceService.getResouceUrl().replace(API_BASE_URL, '/api');
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState({
    getAll: false,
    delete: false,
    update: false,
  });
  const [error, setError] = useState({
    getAll: false,
    delete: false,
    update: false,
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [editData, setEditData] = useState(editResourceObject);

  const getAllData = async (withFeedback: boolean = true) => {
    if (!authentication.authentication?.access_token) return;

    setLoading({ ...loading, getAll: true });
    try {
      const response = await resourceService.getAll(authentication.authentication.access_token);

      setData(response);

      if (withFeedback) {
        dispatch(snackbarActions.setSuccessMessage(`${resourceUrl} erfolgreich geladen`) as any);
      }
    } catch (exception) {
      setError({ ...error, getAll: true });

      dispatch(snackbarActions.setErrorMessage('Fehler beim Laden der Daten') as any);
    }

    setLoading({ ...loading, getAll: false });
  }

  const deleteData = async (id: number) => {
    if (!authentication.authentication?.access_token) return;

    setLoading({ ...loading, delete: true });
    try {
      await resourceService.delete(authentication.authentication.access_token, id);

      dispatch(snackbarActions.setSuccessMessage(`ID ${id} erfolgreich gelöscht`) as any);

      getAllData();
    } catch (exception) {
      setError({ ...error, delete: true });

      dispatch(snackbarActions.setErrorMessage(`Fehler beim Löschen der ID ${id}`) as any);
    }

    setLoading({ ...loading, delete: false });
  }

  const updateData = async (data: any) => {
    if (!authentication.authentication?.access_token) return;

    setLoading({ ...loading, delete: true });
    try {
      await resourceService.update(authentication.authentication.access_token, data);

      dispatch(snackbarActions.setSuccessMessage(`ID ${data['id']} erfolgreich geändert`) as any);

      getAllData(false);
    } catch (exception) {
      setError({ ...error, delete: true });

      dispatch(snackbarActions.setErrorMessage(`Fehler beim Ändern der Daten`) as any);
    }

    setLoading({ ...loading, delete: false });
  }

  const createData = async () => {
    if (!authentication.authentication?.access_token) return;

    setLoading({ ...loading, delete: true });
    try {
      await resourceService.create(authentication.authentication.access_token, editData);

      dispatch(snackbarActions.setSuccessMessage(`Item erfolgreich erstellt`) as any);
      setEditData(editResourceObject);

      getAllData(false);
    } catch (exception) {
      setError({ ...error, delete: true });

      dispatch(snackbarActions.setErrorMessage(`Fehler beim Erstellen der Daten`) as any);
    }

    setLoading({ ...loading, delete: false });
  }

  const openDialogHandler = () => {
    setOpenDialog(true);
  }

  const closeDialogHandler = () => {
    setOpenDialog(false);
  }

  return {
    resourceUrl,
    data,
    loading,
    error,
    getAllData,
    deleteData,
    updateData,
    createData,
    openDialog,
    openDialogHandler,
    closeDialogHandler,
    editData,
    setEditData,
  }
}

export default useDataContainerController;