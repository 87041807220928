import { ReactNode } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { INITIAL_COORDINATES } from '../../../common/constants/constants';
import './RoutingMapContainer.css';

interface RoutingMapContainerProps {
  children: ReactNode;
}

function RoutingMapContainer(props: RoutingMapContainerProps) {
  const { children } = props;

  return (
    <MapContainer
      center={INITIAL_COORDINATES}
      zoom={13}
      className="map-container"
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
    </MapContainer>
  )
}

export default RoutingMapContainer;