import { GeoresultInterface } from './georesult.interface';
import { GeoresultServiceInterface } from './georesult-service.interface';
import { SimplifiedGeoresultInterface } from './simplified-georesult.interface';
import axios from 'axios';
import { Logger } from '../../utils/logger';
import { API_BASE_URL } from '../../common/constants/constants';

export class GeoresultService implements GeoresultServiceInterface {
    constructor() {
        this.logger = new Logger(this.constructor.name);
        this.resource_url = `${API_BASE_URL}/georesult`;
    }

    readonly resource_url: string;
    readonly logger: Logger;

    async getGeoresults(token: string): Promise<GeoresultInterface> {
        try {
            const response = await axios.get<GeoresultInterface>(this.resource_url, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} fetched successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }

    async getSimplifiedGeoresults(token: string): Promise<SimplifiedGeoresultInterface> {
        try {
            const response = await axios.get(`${this.resource_url}/simplified`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} fetched successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }

    async getGeoresultsFile(token: string): Promise<any> {
        try {
            const response = await axios.get(`${this.resource_url}/file`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                responseType: 'arraybuffer'
            });

            this.logger.log(`${this.resource_url} fetched successfully.`);

            return response;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }

    async saveGeoresult(token: string, file: HTMLInputElement): Promise<string | any> {
        try {
            if (!file.files) return;

            const formData = new FormData();
            formData.append('file', file.files[0]);

            const response = await axios.post<string>(`${this.resource_url}/file`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            this.logger.log(`${this.resource_url} fetched successfully.`);

            return response.data;
        } catch (error) {
            this.logger.error(error);

            throw error;
        }
    }
}