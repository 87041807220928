import { RouteRessource, RouteRessourceGroup } from './route-ressource.interface';
import { HomeOutlined, Person2Outlined, FindInPageOutlined, PedalBikeOutlined, LiveHelpOutlined, DnsOutlined, RememberMeOutlined, CorporateFareOutlined, ToggleOnOutlined, ShieldOutlined, WarningOutlined } from '@mui/icons-material';
import SendIcon from '@mui/icons-material/Send';
import MapIcon from '@mui/icons-material/Map';
import { RoleType } from './roles.enum';
import Users from '../components/content/data/Users/Users';
import Overview from '../components/content/Overview/Overview';
import Documentation from '../components/content/Documentation/Documentation';
import Biketypes from '../components/content/data/Biketypes/Biketypes';
import Instructions from '../components/content/Instructions/Instructions';
import Logs from '../components/content/Logs/Logs';
import MobileUsers from '../components/content/data/MobileUsers/MobileUsers';
import Organisations from '../components/content/data/Organisations/Organisations';
import AnalysationData from '../components/content/AnalysationData/AnalysationData';
import NotificationService from '../components/content/NotificationService/NotificationService';
import MobileConfigurations from '../components/content/data/MobileConfigurations/MobileConfigurations';
import Roles from '../components/content/data/Roles/Roles';
import Problempoints from '../components/content/data/Problempoints/Problempoints';

export const routesRessources: RouteRessourceGroup[] =
    [
        {
            name: 'Übersicht',
            family: [
                {
                    path: '/home',
                    element: <Overview />,
                    name: 'Übersicht',
                    icon: <HomeOutlined className="icon" />,
                    roles: [RoleType.ADMINISTRATOR, RoleType.GUEST]
                },
                {
                    path: '/documentation',
                    element: <Documentation />,
                    name: 'Dokumentation',
                    icon: <FindInPageOutlined className="icon" />,
                    roles: [RoleType.ADMINISTRATOR, RoleType.GUEST]
                },
                {
                    path: '/instructions',
                    element: <Instructions />,
                    name: 'Anleitung',
                    icon: <LiveHelpOutlined className="icon" />,
                    roles: [RoleType.ADMINISTRATOR, RoleType.GUEST]
                },
                {
                    path: '/logs',
                    element: <Logs />,
                    name: 'Server Logs',
                    icon: <DnsOutlined className="icon" />,
                    roles: [RoleType.ADMINISTRATOR]
                }
            ]
        },
        {
            name: 'Services',
            family: [
                {
                    path: '/analysation-data',
                    element: <AnalysationData />,
                    name: 'Analysedaten',
                    icon: <MapIcon className="icon" />,
                    roles: [RoleType.ADMINISTRATOR, RoleType.GUEST]
                },
                {
                    path: '/notification-service',
                    element: <NotificationService />,
                    name: 'Benachrichtigungsservice',
                    icon: <SendIcon className="icon" />,
                    roles: [RoleType.ADMINISTRATOR, RoleType.GUEST]
                },

            ]
        },
        {
            name: 'Daten',
            family: [
                {
                    path: '/user',
                    element: <Users />,
                    name: 'Benutzer',
                    icon: <Person2Outlined className="icon" />,
                    roles: [RoleType.ADMINISTRATOR]
                },
                {
                    path: '/roles',
                    element: <Roles />,
                    name: 'Rollen',
                    icon: <ShieldOutlined className="icon" />,
                    roles: [RoleType.ADMINISTRATOR]
                },
                {
                    path: '/biketypes',
                    element: <Biketypes />,
                    name: 'Biketypes',
                    icon: <PedalBikeOutlined className="icon" />,
                    roles: [RoleType.ADMINISTRATOR, RoleType.GUEST]
                },
                {
                    path: '/problempoints',
                    element: <Problempoints />,
                    name: 'Problempunkte',
                    icon: <WarningOutlined className="icon" />,
                    roles: [RoleType.ADMINISTRATOR, RoleType.GUEST]
                },
                {
                    path: '/mobileusers',
                    element: <MobileUsers />,
                    name: 'Mobile Benutzer',
                    icon: <RememberMeOutlined className="icon" />,
                    roles: [RoleType.ADMINISTRATOR, RoleType.GUEST]
                },
                {
                    path: '/mobileconfigurations',
                    element: <MobileConfigurations />,
                    name: 'App-Konfigurationen',
                    icon: <ToggleOnOutlined className="icon" />,
                    roles: [RoleType.ADMINISTRATOR, RoleType.GUEST]
                },
                {
                    path: '/organisations',
                    element: <Organisations />,
                    name: 'Organisationen',
                    icon: <CorporateFareOutlined className="icon" />,
                    roles: [RoleType.ADMINISTRATOR, RoleType.GUEST]
                }
            ]
        }
    ];

export const routesRessourcesFlat: RouteRessource[] = routesRessources.map(routeFamily => routeFamily.family).flat();